import React from "react";

import styled from "styled-components";

//assets

//styles
import typographySizes from "src/assets/styles/typographySizes.js";

//components
import TextWithLineBreaks from "src/components/global/typography/TextWithLineBreaks.js";

const BodyP = styled.p`
  font-size: ${typographySizes.s}rem;

  line-height: ${typographySizes.sh};
`;

const Body = ({ children, className }) => {
  return (
    <BodyP className={className}>
      <TextWithLineBreaks text={children} />
    </BodyP>
  );
};

export default Body;
