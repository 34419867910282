import React from "react";
import styled from "styled-components";

//assets
import Logo from "src/assets/images/logo.inline.svg";
import LogoShort from "src/assets/images/logo-short.inline.svg";

//styles
import typographySizes from "src/assets/styles/typographySizes.js";
import distances from "src/assets/styles/distances.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

//components

import MainWrapper from "src/components/global/MainWrapper.js";
import Body from "src/components/global/typography/Body.js";

const Wrapper = styled.footer`
  background-color: ${colors.dark};
  padding: ${distances.gap}rem;
  border-radius: ${distances.radius}rem;
  margin-bottom: ${distances.pageMargin}rem;
  @media (max-width: ${mediaQuery.tablet}px) {
    padding: calc((${distances.gap / 2}rem - ${distances.pageMargin}rem));
  }
`;
const StyledBody = styled(Body)`
  color: ${colors.darkGrey};
`;
const StyledLogo = styled(Logo)`
  width: 91.66%;
  @media (max-width: ${mediaQuery.tablet}px) {
    display: none;
  }
`;

const StyledLogoShort = styled(LogoShort)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}px) {
    display: block;
  }
`;

const TopRow = styled.div`
  margin-bottom: ${distances.space - distances.gap * 2}rem;
  display: flex;
  flex-wrap: wrap;
`;

const TopColumn = styled.div`
  margin-right: ${distances.gap * 2}rem;
  margin-bottom: ${distances.gap * 2}rem;
  ${StyledBody} {
    max-width: 190rem;
  }
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: ${mediaQuery.tablet}px) {
    margin-bottom: ${distances.gap}rem;
  }
`;

const ColumnTitle = styled(Body)`
  margin-bottom: ${distances.gap / 2}rem;
  color: ${colors.lightGrey};
`;

const Link = styled.a`
  font-size: ${typographySizes.sm}rem;
  margin-top: ${distances.gap}rem;
  text-decoration: none;
  color: ${colors.darkGrey};
  display: inline-block;
  @media (max-width: ${mediaQuery.tablet}px) {
    margin-top: ${distances.gap / 2}rem;
  }
`;

const BottomRow = styled.div`
  margin-top: ${distances.gap}rem;
  padding-top: ${distances.gap}rem;
  border-top: 2rem ${colors.darkGrey} solid;
  width: 100%;
  @media (max-width: ${mediaQuery.tablet}px) {
    margin-top: ${distances.gap / 2}rem;
    padding-top: ${distances.gap / 2}rem;
  }
`;

const Footer = ({}) => {
  return (
    <>
      <MainWrapper id="footer">
        <Wrapper>
          <TopRow>
            <TopColumn>
              <ColumnTitle>Email</ColumnTitle>
              <StyledBody>
                Skontaktuj się, aby uzyskać więcej informacji.
              </StyledBody>
              <Link href="mailto:bartosz.jakobek@adwokatura.pl">
                bartosz.jakobek@adwokatura.pl
              </Link>
            </TopColumn>
            <TopColumn>
              <ColumnTitle>Telefon</ColumnTitle>
              <StyledBody>
                Zadzwoń, aby porozmawiać o swojej sprawie.
              </StyledBody>
              <Link href="tel:+48605285810"> +48 605 285 810</Link>
            </TopColumn>
            <TopColumn>
              <ColumnTitle>Adres</ColumnTitle>
              <StyledBody>Odwiedź mnie w mojej kancelarii.</StyledBody>
              <Link href="mailto:bartosz.jakobek@adwokatura.pl">
                ul. Widok 8/5p, 00-023 Warszawa
              </Link>
            </TopColumn>
          </TopRow>
          <StyledLogo />
          <StyledLogoShort />
          <BottomRow>
            <StyledBody>© 2024 Bartosz Jakóbek</StyledBody>
          </BottomRow>
        </Wrapper>
      </MainWrapper>
    </>
  );
};

export default Footer;
