const typographySizes = {
  xs: 10,
  ss: 14,
  ssh: 1.4,
  s: 14,
  sh: 1.4,
  sm: 18,
  smh: 1.4,
  m: 32,
  mh: 1,
  l: 48,
  lh: 1,
  xl: 72,
  xlh: 1,
};

export default typographySizes;
