import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const MainWrapper = styled.section`
  padding: 0 ${distances.pageMargin}rem;
  width: calc(100% - ${distances.pageMargin * 2}rem);
  position: relative;
  height: 100%;
  @media (max-width: ${mediaQuery.tablet}px) {
  }
`;

export default MainWrapper;
