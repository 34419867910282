const distances = {
  pageMargin: 5,

  gap: 40,
  gapSmall: 20,
  space: 140,
  radius: 5,
};

export default distances;
